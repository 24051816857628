.panel-menu {
    display: none;
    color: white;
    width: 38px;
    height: 38px;
    margin-left: 10px;
    cursor: pointer;
}

.media-hide {
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
}

.media-panel-section {
    position: fixed;
    min-width: 100%;
    max-height: 200px;
    justify-content: flex-start;
    z-index: 1002;
    display: flex;
    background-color: transparent;
    margin-top: 45px;
    display: none;
    overflow: hidden;
    align-items: flex-start;
}

.media-panel {
    display: flex;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0px 0px 12px 12px;
    overflow: hidden;
    max-height: 0px;
}
  
.media-button-last {
  border-radius: 0px 0px 12px 12px;
}
  
.media-panel-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;

}
  
.media-panel-buttons button {
  cursor: pointer;
  height: 50px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  backdrop-filter: blur(15px);
  background-color: transparent;
  transition: 0.2s;
  background-color: #252525;
  color: #ffffff;
  opacity: 1; 
  width: 100%;

}
  
.media-panel-buttons button:hover {
  background-color: #313131 !important;
  color: white !important;
}

#media-open-animation {
    transition: max-height 0.4s ease-in-out;
}


.media-panel-buttons-light button {
    background-color: #408321 !important;
    color: #ffffff !important;
}
  
.media-panel-buttons-light button:hover {
  background-color: #306319 !important;
  color: white !important;
}



@media (max-width: 810px) {
    .media-hide {
        display: none;
    }
    .panel-menu {
        display: flex;
    }
    .panel-logo {
        display: none;
    }
    .media-dark {
        background-color: #252525;
    }
    .media-panel-section {
        display: flex;
    }
    .media-light {
        background-color: #408321;
    }
}