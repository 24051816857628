@media (max-width: 810px) {
    .main-buttons {
        flex-direction: column;
        margin-top: 20px;
        width: 60%;
    }

    .main-buttons button {
        margin-top: 20px;
    }

    .main-inline h1 {
        font-size: 38px;
    }

    .main-inline h2 {
        font-size: 32px;
    }

    .main-text-container {
        text-align: center;
        margin: 10px;
    }

    .wave {
        margin-top: 0;
    }

    main {
        height: 600px;
    }

    .description-block {
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        margin-top: 10px;
    }

    .description-block-text {
        text-align: center;
        margin-bottom: 70px;
    }

    .world {
        margin-bottom: 30px;
    }

    section {
        height: 470px;
    }

    .last-block {
        flex-direction: column;
        justify-content: center;
        align-self: center;
        display: flex;
    }

    .last-container {
        justify-content: center;
        width: 100%;
        text-align: center;
    }

    .last-image {
        width: 100%;
        justify-content: center;
        align-items: center 
    }

    .last-container button {
        width: 60%;
        font-size: 18px;
    }

    .section-media {
        height: 500px;
    }
}

@media (max-width: 700px) {
    main {
        height: 750px;
    }

    .main-inline {
        flex-direction: column
    }

    .main-inline h1 {
        margin: 0;
    }

    .main-text-container {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 580px) {
    main {
        height: 770px;
    }

    .main-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-buttons button {
        width: 50%;
    }
}

/*@media (max-width: 530px) {
    section {
        padding-top: 100px;
    }
}*/

@media (max-width: 370px) {
    main {
        height: 830px;
    }
    
    section {
        padding-top: 100px;
    }
}