html, body, #app, #app>div {
    margin: 0px; 
    padding: 0px;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    width: 1ch;
}

body::-webkit-scrollbar-track {
  background: #242424;
}

body::-webkit-scrollbar-thumb {
  background-color: #585858;
  border-radius: 10px;
  border: none;
}

.container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

::-moz-selection {
    color: rgb(194, 194, 194);
    background: rgba(105, 105, 105, 0.2);
}

::selection {
    color: rgb(194, 194, 194);
    background: rgba(105, 105, 105, 0.2);
}

header {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    flex-direction: row;
    position: fixed;
    backdrop-filter: blur(15px);
    z-index: 1000;
}

/*
    .collision {  Отвечало за коллизию панели вначале страницы 
        width: 100%;
        height: 45px;
    }
*/

.get-app {
    min-width: 200px;
}

main {
    padding-top: 45px;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(109,109,109);
    background: rgba(50,50,50,1);
    border-bottom: #8F8F8F;
    border: 2px;
}

.main-text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    z-index: 1000;
}

main h1 {
    font-family: "Manrope", sans-serif;
    font-size: 46px;
    color: white;
    margin-top: 45px;
    font-family: 500;
    z-index: 1000;
}

main h2 {
    font-family: "Manrope", sans-serif;
    font-size: 22px;
    color: white;
    font-weight: 200;
    z-index: 1000;
}

.main-inline {
    display: flex;
    flex-direction: row;
}

.main-inline h1 {
    z-index: 1000;
    margin: 0;
    margin-left: 10px;

}

.typing-text {
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

.main-buttons {
    width: 80%;
    flex: 1;
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.main-buttons button { 
    height: 55px;
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    transition: 0.2s;
    min-width: 300px;
    background-color: #5c5c5c;
    color: white;
    cursor: pointer;
    z-index: 1000;
}

.main-buttons button:hover {
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
    background-color: #4b4b4b;
}

section {
    width: 100%;
    height: 400px;
    background-color: rgb(20, 20, 20);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    z-index: 10;
}

.description-block-text {
    padding-top: 35px;
    height: 50%;
    font-size: 32px;
}

.description-block-text h1 {
    font-family: "Manrope", sans-serif;
    font-weight: 500;
}

.description-block-text h3 {
    font-family: "Manrope", sans-serif;
    font-weight: 500;
}

.description-block {
    height: 100%;
    display: flex;
    justify-content: space-around;
    padding-left: 35px;
    padding-right: 35px;
    box-sizing: border-box;
    width: 100%;
}

.reversed-row {
    flex-direction: row-reverse;
}

.image-description-block {
    width: 330px;
    height: 330px;
    transition: 0.5s;
    position: relative;
}

.image-description-block:hover {
    scale: 1.1
}

.world {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    width: 250px;
    height: 250px;
}

.world:hover {
    scale: 1.1
}

.rocket {
    transform: translate(-50%, -50%);
    animation: shake 0.1s infinite;
}


@keyframes shake {
    0% {
      transform:translate3d(0, 0, 0);
    }
    50% {
      transform:translate3d(-0.1rem, 0, 0);
    }
    100% {
      transform:translate3d(0.1rem, 0, 0);
    }
}

.last-block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 0px;
    background: linear-gradient(360deg, rgba(50,50,50,1) 0%, rgb(20, 20, 20) 100%);
}

.last-container {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.last-image {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
}

.last-image img {
    width: 200px;
    height: 200px;
}

.sattelite {
    animation: sattelite 15s infinite;
    transform: translate(-50%, -50%);
}

@keyframes sattelite {
    0% {
      transform:translate3d(0, 0, 0);
      rotate: 0deg;
    }
    25% {
      transform:translate3d(0, 18px, 0);
      rotate: 10deg;
    }
    50% {
      transform:translate3d(0, 10px, 0);
      rotate: 0deg;
    }
    75% {
        transform:translate3d(0, 18px, 0);
        rotate: -16deg;
    }
    100% {
      transform:translate3d(0, 0, 0);
      rotate: 0deg;
    }
}

.last-container h1 {
    font-family: "Manrope", sans-serif;
    font-size: 34px;
    color: white;
    margin: 20px;
}

.last-container h3 {
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    color: white;
    font-weight: 300;
}

.last-container button { 
    height: 55px;
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    transition: 0.2s;
    min-width: 300px;
    background-color: #5c5c5c;
    color: white;
    cursor: pointer;
    margin-top: 30px;
}

.last-container button:hover {
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
    background-color: #4b4b4b;
}