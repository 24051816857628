@media (max-width: 810px) {
    footer {
        flex-direction: column;
        height: 740px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .footer-bars-container h3 {
        margin-top: 35px;
    }
    .footer-media {
        flex-direction: row;
        margin-bottom: 10px;
    }
    .footer-media-icons-container {
        margin-right: 12px;
    }
}