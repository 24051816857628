@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Candal&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Candal&family=Mako&display=swap');

html, body, #app, #app>div {
    margin: 0; 
    padding: 0px;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pointer {
    cursor: pointer;
}

.tooltip-red {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 10px 15px;
    background-color: rgb(220, 0, 0);
    color: #fff;
    border-radius: 5px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ff0000;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    margin-top: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
    z-index: 10001;
    max-width: 40%;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.tooltip-red.show {
  transform: translate(-50%, 0);
  opacity: 1;
}

.yellow {
    background-color: rgb(232, 236, 0);
    border: 1px solid #fffb00;
    color: rgb(44, 44, 44)
}

.green {
    background-color: rgb(0, 175, 0);
    border: 1px solid #11d100;
}

@media (max-width: 580px) {
    .tooltip-red {
        min-width: 85%;
    }
}