footer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
    width: 100%;
    height: 250px;
    border-top: 1px solid #565656;
    padding-top: 35px;
    box-shadow: 5px 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.footer-logo {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo img {
    width: 125px;
    max-height: 125px;
    cursor: pointer;
}

.footer-bars-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.footer-bars-container h4 {
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    color: white;
    font-family: "Inter", sans-serif;
    margin: 6px;
    transition: 0.15s;
    text-align: center;
}

.footer-bars-container h4:hover {
    font-size: 19px;
    font-weight: 600;
}

.footer-bars-container h3 {
    font-size: 21px;
    font-weight: 600;
    color: rgb(55, 204, 77);
    font-family: "Inter", sans-serif;
    margin: 12px;
    text-align: center;

}

.footer-media {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.footer-media div {
    border-radius: 100px;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-top: 12px;
}

.media-icon {
    color: white;
    width: 70%;
    height: 70%;
}