header {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    flex-direction: row;
    position: fixed;
    backdrop-filter: blur(15px);
    z-index: 1001;
}

.get-app {
    min-width: 200px;
}

.panel-logo {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    cursor: pointer;
}

.panel-buttons {
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
}

.panel-last-section {
    height: 100%;
    max-width: 230px;
    display: flex;
    color: white;
}


.panel-buttons button {
    width: 125px;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    border: none;
    border-radius: 2;
    transition: 0.2s;
    background-color: transparent;
    font-size: 15px;
    color: rgb(255, 255, 255);
    margin-left: 5px;
    position: relative;
	cursor: pointer;
	line-height: -100px;
	text-decoration: none;
}

.panel-buttons button:hover {
    background-color: rgba(163, 163, 163, 0.07);
    font-weight: 700;
}

.panel-buttons button:after {
	display: block;
	position: absolute;
	left: 0;
	width: 0;
	height: 2px;
    margin-top: 30px;
	background-color: #ffffff;
	content: "";
	transition: width 0.35s ease-in-out;
}

.panel-buttons button:hover:after,
.panel-buttons button:focus:after {
	width: 90%;
}
